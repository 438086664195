import React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'
import CopyButton from '../../CopyButton'
import { useSelector } from 'react-redux'
import { AppState } from '../../../state/store'
import { retrieveUser } from '../../../state/auth'
import { User } from '../../../interfaces/ciInterfaces'
import Link from '../../Link'

interface IRequestSandboxModalProps {
  handleClose: Function
}

const RequestSandboxModal: React.FC<IRequestSandboxModalProps> = ({
  handleClose,
}) => {
  const { t } = useTranslation()
  const user = useSelector((state: AppState) => retrieveUser(state))
  const userEmail =
    (user as User).emails?.find(({ primary }) => !!primary)?.value || ''

  const copyText = (): string => {
    return `${t('sandbox.requestTicketModalForm.body')}

    ${t('sandbox.requestTicketModalForm.requesterInformation')}
    ${t('sandbox.requestTicketModalForm.emailId')} ${userEmail}
    ${t('sandbox.requestTicketModalForm.firstName')} ${
      (user as User).name.familyName
    }
    ${t('sandbox.requestTicketModalForm.lastName')} ${
      (user as User).name.givenName
    }
    ${t('sandbox.requestTicketModalForm.orgId')} ${
      (user as User).meta.organizationID
    }`
  }

  return (
    <>
      <ModalHeader headerLabel={t('sandbox.zendeskModalTitle')} />
      <ModalBody className="sandbox-modal-body">
        <p className="sandbox-request-form-text">
          {t('sandbox.zendeskModalDescription')}
        </p>
        <hr />
        <div className="copy-ticket-header">
          <div className="copy-ticket-title">
            {t('sandbox.zendeskCopyTitle')}
          </div>
          <div className="copy-ticket-button">
            <CopyButton textToCopy={copyText()} />
          </div>
        </div>
        <div className="copy-ticket-body">
          <p>{t('sandbox.requestTicketModalForm.body')} </p>
          <br />
          <span className="title">
            {t('sandbox.requestTicketModalForm.requesterInformation')}
          </span>
          <p>
            <span className="props">
              {t('sandbox.requestTicketModalForm.emailId')}
            </span>
            {userEmail}
            <br />
            <span className="props">
              {t('sandbox.requestTicketModalForm.firstName')}
            </span>
            {(user as User).name.givenName}
            <br />
            <span className="props">
              {t('sandbox.requestTicketModalForm.lastName')}
            </span>
            {(user as User).name.familyName}
            <br />
            <span className="props">
              {t('sandbox.requestTicketModalForm.orgId')}
            </span>
            {(user as User).meta.organizationID}
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="request-modal-buttons">
          <Button
            onClick={(): void => {
              handleClose()
            }}
            ariaLabel={t('actionButtons.close')}
            color="default"
            data-cy="close-btn"
            data-testid="close-btn"
          >
            {t('actionButtons.close')}
          </Button>
          <Link
            to="https://devsupport.webex.com/hc/en-us/requests/new"
            className="md-button md-button--blue"
            target="_blank"
            rel="noopener noreferrer"
            data-cy="request-btn"
          >
            <span>{t('sandbox.zendeskButton')}</span>
          </Link>
        </div>
      </ModalFooter>
    </>
  )
}

export default RequestSandboxModal
